<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="formgrid grid">
              <div class="field lg:col-2 xl:col-2 sm:col-2">
                <label for="mes">Mês</label>
                <select id="mes"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  placeholder="Mês" v-model="mes">
                  <option v-for="mes in meses" :key="mes.value" :value="mes.value">
                    {{ mes.label }}
                  </option>
                </select>
              </div>
              <div class="field lg:col-2 xl:col-2 sm:col-2">
                <label for="ano">Ano</label>
                <select id="ano"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  placeholder="Status" v-model="ano">
                  <option v-for="ano in anos" :key="ano.value" :value="ano.value">
                    {{ ano.label }}
                  </option>
                </select>
              </div>
              <div class="field lg:col-2 xl:col-2 sm:col-2">
                <label for="status">UF</label>
                <select id="status"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  placeholder="UF" v-model="uf">
                  <option v-for="uf in ufs" :key="uf.value" :value="uf.value">
                    {{ uf.label }}
                  </option>
                </select>
              </div>
              <div class="field lg:col-3 xl:col-3 sm:col-3">
                <label for="status">Status</label>
                <select id="status"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  placeholder="Status" v-model="status">
                  <option v-for="status in status_array" :key="status.value" :value="status.value">
                    {{ status.label }}
                  </option>
                </select>
              </div>

              <div class="field lg:col-3 xl:col-2 sm:col-63">
                <Button type="button" style="margin-top: 25px; padding: 5px 10px;;" class="mr-2 mb-2 " label="Filtrar"
                  icon="pi pi-search" :loading="loading" @click="filtrar()" />
              </div>
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload moe="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Implantaçoes -->
        <DataTable :loading="loading" csvSeparator=";" ref="dt" :value="faturas" dataKey="id" :paginator="true" :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">XML Capturados</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>
          <template #empty> Nenhum resultado encontrado. </template>
          <template #loading> Aguarde, carregando... </template>
          <Column field="uc" header="Unidade" :sortable="true" headerStyle="width:14%; min-width:5rem;">
            <template #body="slotProps">
              <span class="p-column-title">Unidade</span>
              {{ slotProps.data.uc }}
            </template>
          </Column>
          <Column field="mes_ref" header="Mês Referência" :sortable="true" headerStyle="width:15%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Mês Referência</span>
              {{ slotProps.data.mes_ref }}
            </template>
          </Column>
          <Column field="status" header="Status" :sortable="true" headerStyle="width:15%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <Button label="Processada BRNotas" v-if="slotProps.data.status == 1" severity="success" text />
              <Button label="Aguardando Envio BRNotas" v-if="slotProps.data.status == 0" severity="warning" text />
              <Button label="Erro no Envio BRNotas" v-if="slotProps.data.status == 2" severity="danger" text />
            </template>
          </Column>
          <Column field="log_retorno" header="Log Retorno" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">log</span>
              <a :href="slotProps.data.log_retorno" target="_blank">
                <Button class="p-button-rounded p-button-secondary mr-2" style="font-size:12px;padding: 5px;"
                  @click="mostrarLog(slotProps.data)">Log API
                  XML</Button>

              </a>
            </template>
          </Column>
          <Column field="arquivo" header="Arquivo" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Arquivo</span>
              <a :href="slotProps.data.arquivo" target="_blank">
                <Button class="p-button-rounded p-button-secondary mr-2" style="font-size:12px;padding: 5px;">Download
                  XML</Button>

              </a>
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;" header="Ações">
            <template #body="slotProps">
              <Button style="font-size: 12px; padding: 5px;" :loading="loading" label="Enviar API BRNotas"
                class="p-button-rounded p-button-primary mr-2" @click="enviarNota(slotProps.data)" />

            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="logDialog" :style="{ width: '800px' }" header="Log Retorno BRNotas" :modal="true"
    class="p-fluid">
    <pre>
    <code>
      {{ log }}
    </code>
  </pre>
  </Dialog>

</template>

<script>

import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      log: "",
      loading: 0,
      logDialog: false,
      mes: new Date().getMonth() + 1,
      ano: new Date().getFullYear(),
      status: "",
      ufs: [
        { label: "", value: "" },
        { label: "AC", value: "AC" },
        { label: "AL", value: "AL" },
        { label: "AP", value: "AP" },
        { label: "AM", value: "AM" },
        { label: "BA", value: "BA" },
        { label: "CE", value: "CE" },
        { label: "DF", value: "DF" },
        { label: "ES", value: "ES" },
        { label: "GO", value: "GO" },
        { label: "MA", value: "MA" },
        { label: "MT", value: "MT" },
        { label: "MS", value: "MS" },
        { label: "MG", value: "MG" },
        { label: "PA", value: "PA" },
        { label: "PB", value: "PB" },
        { label: "PR", value: "PR" },
        { label: "PE", value: "PE" },
        { label: "PI", value: "PI" },
        { label: "RJ", value: "RJ" },
        { label: "RN", value: "RN" },
        { label: "RS", value: "RS" },
        { label: "RO", value: "RO" },
        { label: "RR", value: "RR" },
        { label: "SC", value: "SC" },
        { label: "SP", value: "SP" },
        { label: "SE", value: "SE" },
        { label: "TO", value: "TO" }
      ],
      meses: [
        { label: "Janeiro", value: "1" },
        { label: "Fevereiro", value: "2" },
        { label: "Março", value: "3" },
        { label: "Abril", value: "4" },
        { label: "Maio", value: "5" },
        { label: "Junho", value: "6" },
        { label: "Julho", value: "7" },
        { label: "Agosto", value: "8" },
        { label: "Setembro", value: "9" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" }

      ],
      status_array: [
        { label: "", value: "" },
        { label: "Aguardando Envio BRNotas", value: "0" },
        { label: "Enviado BRNotas (Sucesso)", value: "1" },
        { label: "Enviado BRNotas (Erro)", value: "2" },
      ],
      uf: "",
      anos: [],
      faturas: [],
      fatura: {},

      filters: {},
      submitted: false,



    };
  },

  created() {
    this.initFilters();
  },
  mounted() {

    // Obtendo o ano atual
    const anoAtual = new Date().getFullYear();

    let anos = [];

    // Loop do ano atual até 2017
    for (let ano = anoAtual; ano >= 2024; ano--) {
      anos.push({ label: `${ano}`, value: `${ano}` });
    }

    this.anos = anos;
    // funçoes importadas do arquivo funcoes.js
    this.listarFaturas();




  },


  methods: {

    mostrarLog(data) {
      this.logDialog = true;
      this.log = data.log_retorno;
    },

    filtrar() {
      this.loading = true;
      this.listarFaturas();
    },

    listarFaturas() {
      this.loading = true;
      let data = {
        ano: this.ano,
        mes: this.mes,
        status: this.status,
        uf: this.uf
      }

      this.axios
        .post("/xml/listar", data)
        .then((response) => {
          this.faturas = response.data.dados;
          this.loading = false;

        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
          this.loading = false;
        });

    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    enviarNota(nota) {
      this.loading = true;

      this.axios
        .post("/xml/enviar", nota)
        .then(() => {
          this.listarFaturas();
          this.loading = false;

        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
          this.loading = false;
        });
    },


    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

  },
};


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>